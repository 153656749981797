<template>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="small-box bg-teal-active pointer" v-on:click="$router.push('/reviews')">
            <div class="inner">
                <h3>{{ reviews_count }}</h3>
                <h5>Отзывов</h5>
            </div>
            <div class="icon">
                <i class="fas fa-sticky-note"></i>
            </div>
            <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
                <i class="fa font-scale fa-arrow-circle-right"></i>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'reviews-box-item',

        props: {
            reviews_count: {
                type: Number,
                required: true,
                default: 0,
            },
        }
    }
</script>